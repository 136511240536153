import instance from "./instance";

export default {
  /* ********* 电台 ********* */
  // 最热主播
  djToplist: (limit = 30) =>
      instance.get(`/dj/toplist/popular?limit=${limit}`),
  // 主播电台catelist
  djCatelist: () => instance.get("/dj/catelist"),
  // 节目排行榜
  djProgramToplist: (limit = 10) =>
      instance.request({
        url: `/dj/program/toplist?limit=${limit}`,
        method: "get",
      }),
  // 24 小时节目榜
  djProgramToplistHours: (limit = 10) =>
      instance.request({
        url: `/dj/program/toplist/hours?limit=${limit}`,
        method: "get",
      }),
  // 类别热门电台
  djRadioHot: ({limit = 30, cateId, offset = 0}) => {
    return instance.get(`/dj/radio/hot`, {params: {cateId, limit, offset}});
  },
}