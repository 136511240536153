<template>
  <div class="footer_content">
    <!-- PC端 -->
    <div class="footer" v-if="!$isAndroid">
      <div class="footer_con">
        <div class="footer_con_p">
          <div class="footer_con_l">
            <div class="footer_con_name">
              广州极砚商贸有限公司
            </div>
            <div class="footer_con_one">
              <img src="@/static/footer/footer_address.png" alt="云南省楚雄市鹿城镇鹿城南路兆顺第一城B栋19楼">
              <p>广州市天河区中山大道西491号二楼</p>
            </div>
            <div class="footer_con_one">
              <img src="@/static/footer/footer_mobile.png" alt="15217695457|案例-极砚科技-创新科技,共研未来">
              <p>15217695457（同微信）</p>
            </div>
            <div class="footer_con_one">
              <img src="@/static/footer/footer_email.png" alt="企业邮箱|案例-极砚科技-创新科技,共研未来">
              <p>1638802579@qq.com</p>
            </div>
            <!-- <div class="footer_con_one">
              <img src="@/static/footer/BiliBili1.png" alt="BiliBili|案例-极砚科技-创新科技,共研未来">
              <a class="p_red" rel="nofollow" href="https://www.bilibili.com/" target="_blank">BiliBili-极砚小创</a>
              <p></p>
              <img src="https://www.zanku.cn/wp-content/uploads/2021/07/cropped-2021071605554790-180x180.png"
                style="width:25px; height:25px" alt="赞库小轩">
              <a class="p_red" rel="nofollow" href="https://www.zanku.cn/" target="_blank">博客-赞库小轩</a>
            </div> -->
          </div>
          <div class="footer_con_r">
            <div class="footer_con_r_con">
              <img src="@/static/footer/weixin.jpg" alt="案例-极砚科技-创新科技,共研未来">
              <p>对接工程师</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <p>
          Copyright © 2024 极砚 All Rights Reserved.
          <!-- <a rel="nofollow" href="https://beian.miit.gov.cn" target="_blank">广州极砚商贸有限公司</a> -->
        </p>
        <!-- <div class="footer_bottom_p">
          <div class="footer_bottom_p_img">
            <img src="@/static/footer/footer_bottom_img1.png" alt="案例-极砚科技-创新科技,共研未来">
          </div>
          <div class="footer_bottom_p_img">
            <img src="@/static/footer/footer_bottom_img2.png" alt="案例-极砚科技-创新科技,共研未来">
          </div>
          <div class="footer_bottom_p_img">
            <img src="@/static/footer/footer_bottom_img3.png" alt="案例-极砚科技-创新科技,共研未来">
          </div>
        </div> -->
      </div>
    </div>
    <!-- 移动端 -->
    <div class="footer_h5" v-else>
      <div class="business_and">
        <div class="business_and_title">极砚科技</div>
        <div class="heng baiheng"></div>
        <div class="business_and_text">多年系统程序技术开发</div>
        <img src="@/static/footer/weixin.jpg" class="business_and_img">
      </div>
      <div class="list-sup">
        <div class="f-item">
          <div class="title">极砚科技</div>
          <div class="f-num">
            <div class="f-loney">
              <span class="f-zi" @click="pathFun('case')">服务内容</span>
            </div>
            <div class="f-loney">
              <span class="f-zi" @click="pathFun('anli')">案例展示</span>
            </div>
            <div class="f-loney">
              <span class="f-zi" @click="pathFun('contact')">联系我们</span>
            </div>
          </div>
        </div>

        <!-- <div class="f-item">
          <div class="title">媒体平台</div>
          <div class="f-num">
            <div class="f-loney">
              <a href="/">
                <span class="f-zi">B站-极砚小创</span>
              </a>
            </div>
            <div class="f-loney">
              <a href="//zanku.cn">
                <span class="f-zi">博客-赞库小轩</span>
              </a>
            </div>
          </div>
        </div> -->
      </div>
      <div class="sub">
        <p class="copy">
          Copyright © 2024 极砚 All Rights Reserved.<br /><br />广州极砚商贸有限公司
        </p>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'Header',
  data() {
    return {}
  },
  computed: {},
  mounted() { },
  methods: {
    pathFun(url) {
      this.$router.push({ path: url });
    },
  },
}
</script>

<style scoped lang='less'>
/* PC底部 */
.footer {
  width: 100%;
  // height: 365px;
  background-color: #2b2b2b;
  box-sizing: border-box;

  .footer_con {
    width: 100%;
    // height: 264px;
    padding-bottom: 30px;
    border-bottom: 1px solid #414141;
    box-sizing: border-box;

    .footer_con_p {
      display: flex;
      justify-content: space-between;
      height: 215px;
      padding-top: 50px;
      width: 1200px;
      margin: 0 auto;

      .footer_con_l {
        margin-top: -2px;

        .footer_con_name {
          font-size: 18px;
          color: #ffffff;
        }

        .footer_con_one {
          display: flex;
          opacity: 0.7;
          margin-top: 11px;

          img {
            width: 20px;
            height: 23px;
          }

          p {
            font-weight: 300;
            font-size: 16px;
            color: #ffffff;
            margin-left: 12px;
          }

          .p_red {
            font-weight: 300;
            font-size: 16px;
            margin-left: 12px;
            color: #ff7784;
          }
        }
      }

      .footer_con_r {
        display: flex;

        .footer_con_r_con {
          width: 140px;
          height: 165px;
          margin-left: 15px;
          text-align: center;

          img {
            width: 140px;
            height: 140px;
          }

          p {
            font-size: 14px;
            color: #fff;
            margin-top: 6px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .footer_bottom {
    text-align: center;
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;

    p {
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      opacity: 0.3;
      margin-top: 16px;

      a {
        color: #ff6f6f;
        font-size: 14px;
      }
    }

    .footer_bottom_p {
      width: 176px;
      display: flex;
      margin: 0 auto;
      margin-top: 10px;
      justify-content: space-between;
    }

    .footer_bottom_p_img {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      position: relative;
    }
  }
}


/* 移动底部 */
.footer_content {
  width: 100%;
}

.footer_h5 {
  width: 100%;
  background: #212631;
  color: #747474;
  text-align: left;

  .business_and {
    width: 10rem;
    height: 7rem;
    padding: 1px 0;
    box-sizing: border-box;
    background-image: url(~@/static/footer/homedbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .business_and_title {
      font-family: PingFangSC-Medium;
      font-size: 0.54rem;
      font-weight: 700;
      font-stretch: normal;
      line-height: 0.54rem;
      letter-spacing: 0;
      margin: 0.852rem auto 0;
      color: #fff;
      text-align: center;
    }

    .heng {
      width: 0.667rem;
      height: 0.046rem;
      background-color: #FFF;
      border-radius: 0.02rem;
      margin: 0.32rem auto;
    }

    .business_and_text {
      font-size: 0.347rem;
      line-height: 0.347rem;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-stretch: normal;
      letter-spacing: 0;
      color: #fff;
      text-align: center;
    }

    .business_and_img {
      display: block;
      margin: 0.528rem auto 0.444rem;
      width: 3.398rem;
      height: 3.398rem;
      background-color: #fff;
      border-radius: 0.107rem;
      border: 0.027rem solid #f1f1f1;
    }
  }

  .list-sup {
    padding-left: 0.812rem;
    font-family: PingFang-SC-Medium;
    overflow: hidden;
    margin-bottom: 0.7rem;

    .f-item {
      margin-top: 0.84rem;

      .title {
        font-size: 0.364rem;
        color: rgba(255, 255, 255, 1);
        line-height: 0.56rem;
      }

      .f-num {
        display: flex;
        justify-content: left;

        .f-loney {
          .f-zi {
            font-size: 0.336rem;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            opacity: 0.7;
            margin-top: 0.364rem;
            display: block;
            margin-right: 0.476rem;
          }
        }
      }
    }
  }

  .sub {
    border-top: 0.5px solid #3b3b3b;
    overflow: hidden;

    .copy {
      margin: 0.56rem auto;
      text-align: center;
      font-size: 0.336rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(116, 116, 116, 1);
      line-height: 0.42rem;
    }
  }
}
</style>