<template>
  <div class='container'>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AppMain',
}
</script>

<style scoped>
.container {
  width: 100%;
}
</style>