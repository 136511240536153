import Vue from "vue";
import VueRouter from "vue-router";

// 引入nprogress #一款页面加载时进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    // 首页
    path: "/home",
    name: "home",
    component: () => import("@/views/Home/Home"),
    meta: { title: "首页" },
  },
  {
    // 首页
    path: "/case",
    name: "case",
    component: () => import("@/views/Case/Case"),
    meta: { title: "服务" },
  },
  {
    // 案例
    path: "/anli",
    name: "anli",
    component: () => import("@/views/anli/anli"),
    meta: { title: "案例" },
  },
  {
    // 联系我们
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contact/contact"),
    meta: { title: "联系我们" },
  },
  {
    // 极砚OA
    path: "/oa",
    name: "oa",
    component: () => import("@/views/oa/oa"),
    meta: { title: "极砚OA" },
  },
];

const router = new VueRouter({
  mode: 'history', // 使用HTML5的history模式
  routes,
  scrollBehavior() {
    // 跳转路由时页面滚到顶部
    return { x: 0, y: 0 };
  },
});

// 路由跳转前钩子函数中 - 执行进度条开始加载
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title+'-极砚科技-共创未来';
  }
  if (to.query.title) {
    document.title = to.query.title+'-极砚科技-共创未来';
  }
  NProgress.start();
  next();
});

// 路由跳转后钩子函数中 - 执行进度条加载结束
router.afterEach(() => {
  NProgress.done();
});

export default router;
