<template>
  <div id='app' :class="!$isAndroid?'min_App':''">
    <AppMain></AppMain>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import AppMain from '@/components/AppMain'

export default {
  name: 'App',
  components: {
    Footer,
    AppMain,
  },
  mounted() {
    if(this.$isAndroid) {
      const width = document.documentElement.clientWidth || document.body.clientWidth;
      const fontSize = width / 10; // 假设1rem等于设计稿宽度的10等分
      document.documentElement.style.fontSize = `${fontSize}px`;
    }
  }
}
</script>

<style scoped>
#app {
  background-color: #f5f5f5;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.min_App{
  min-width: 1250px;
}
</style>

