import instance from "./instance";

export default {
  // 获取歌手专辑
  artistAlbum: (id, limit = 5) => {
    return instance.get(`/artist/album`, {params: {id, limit}});
  },
  // 热门歌手
  topArtists: (limit = 30, offset = 0) => {
    return instance.get(`/top/artists`, {params: {limit, offset}});
  },
  // 获取歌手详情
  artistDetail: (id) => instance.get(`/artist/detail`, {params: {id}}),
  // 获取歌手列表
  /*
   * 给评论点赞
   * type: -1:全部; 1:男歌手; 2:女歌手; 3:乐队
   * area: -1:全部; 7华语; 96欧美; 8:日本; 16韩国; 0:其他
   * initial: 按首字母索引查找参数, 热门传-1, #传0
   * limit: 30
   * offset: 0
   */
  artistList: ({
     type = -1,
     area = -1,
     initial = "",
     limit = 50,
     offset = 0,
   }) => {
    return instance.get(
        `/artist/list`, {params: {type, area, initial, limit, offset}}
    );
  },
  // 歌手热门50首歌曲
  artistTopSong:(id) => instance.get(`/artist/top/song?id=${id}`),
  // 歌手专辑
  artistAlbums: (id, limit = 12, offset = 0) =>
      instance.get(`/artist/album?id=${id}&limit=${limit}&offset=${offset}`),
  // 获取歌手描述
  artistDesc: (id) => instance.get(`/artist/desc?id=${id}`),
  // 获取相似歌手
  simiArtsit: (id) => instance.get(`/simi/artist?id=${id}`),
}