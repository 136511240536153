import instance from "./instance";

export default {
  // 首页新碟上架数据
  albumNewest: function () {
    return instance.get(
        `/album/newest`
    );
  },
  // 全部新碟
  allAlbum: ({limit = 30, offset = 0, area = "ALL"}) => {
    return instance.request({
      method: "get",
      url: `/album/new`,
      params: {
        area, limit, offset
      }
    });
  },
  // 数字专辑详情
  albumDetail: (id) => {
    return instance.get(`/album`, {params: {id}});
  },
}

