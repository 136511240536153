<template>
  <div>
    <!-- PC端 -->
    <template v-if="!$isAndroid">
      <div class="nav_topchange">
        <div class="nav_top_inner _container">
          <img class="nav_logo" src="@/static/logo.jpg" alt="案例-极砚科技-创新科技,共研未来">
          <div class="nav_top_right">
            <div class="nav_item" @click="pathFun(item.path)" :class="navName == item.path ? 'nav_top_selected' : ''"
              v-for="(item, index) in navList" :key="index">
              {{ item.name }}<span class='home_aline'></span>
            </div>
          </div>
        </div>
      </div>
      <div class="nav_topchange_H"></div>
    </template>
    <template v-else>
      <!-- 移动端 -->
      <div class="navBody" :class="navLeft ? 'slide-enter' : ''">
        <div class="navBody_con">
          <div class="navBody_con_top">
            <img src="@/static/logo.jpg" class="navBody_con_top_logo" @click="pathFun('home')"/>
            <img src="@/static/header/close.png" class="navClose" @click="navLeft = false" />
          </div>
          <div class="navBody_list" @click="pathFun(item.path)" v-for="(item, index) in filteredNavList" :key="index">
            <img :src="resolveIconPath(item.icon)" class="navBody_list_img" />
            <div class="navBody_list_title">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="navBody_H"></div>
      <div class="topnav">
        <img src="@/static/logo.jpg" class="aLogo_img" alt="极砚科技" />
        <img src="@/static/header/open.png" class="open" @click="navLeft = true" />
      </div>
    </template>

  </div>

</template>

<script>
export default {
  name: 'Header',
  props: {
    navName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      navLeft: false,
      navList: [
        {
          name: "首页",
          icon: "",
          path: "home",
        },
        {
          name: "服务",
          icon: "header/h1.png",
          path: "case",
        },
        {
          name: "案例",
          icon: "header/h2.png",
          path: "anli",
        },
        // {
        //   name: "团队",
        //   icon: "header/h3.png",
        //   path: "/tuandui",
        // },
        {
          name: "联系我们",
          icon: "header/h4.png",
          path: "contact",
        },
      ]
    }
  },
  computed: {
    // 过滤掉没有icon的项目
    filteredNavList() {
      return this.navList.filter(item => item.icon !== '');
    },
  },
  mounted() {},
  methods: {
    resolveIconPath(iconPath) {
      // 这里假设有一个函数可以将"@/static/"开头的路径转换为相对于公共路径的URL，这通常在webpack配置中完成。
      // 对于简化，这里我们只返回一个模拟的URL，实际项目中需要结合具体的构建工具配置进行处理。
      return require(`@/static/${iconPath}`); // 使用require动态引入资源
    },
    pathFun(url) {
      if(this.navName != url) {
        this.$router.push({ path: url });
      }
    },
  },
}
</script>

<style scoped lang='less'>
/* PC导航栏 */
.nav_topchange_H {
  width: 100%;
  height: 70px;
}

.nav_topchange {
  width: 100%;
  height: 70px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  position: fixed;
  top: 0;
  z-index: 9;
  left: 0;
  color: #222 !important;
  background: #fff !important;
  transition: all 0.3s ease;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);

  .nav_top_inner {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 100px;

    .nav_logo {
      width: 150px;
      height: auto;
      cursor: pointer;
    }

    .nav_top_right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav_item {
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        color: #222;
        margin-left: 70px;

        &.nav_top_selected,
        &:hover {
          font-weight: bold;
          color: #fd4747 !important;

          .home_aline {
            display: block;
            width: 40px;
            height: 2px;
            background-color: #e15151;
            border-radius: 1px;
            position: absolute;
            bottom: -4px;
            left: 50%;
            margin-left: -20px;
          }
        }
      }
    }
  }
}

/* 移动导航栏 */
.topnav {
  width: 10rem;
  height: 1.343rem;
  background-color: #fff;
  box-shadow: 0 0.12rem 0.157rem 0 rgba(0, 0, 0, 0.08);
  padding: 0 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  z-index: 99;
  position: fixed;
  top: 0;

  .aLogo_img {
    height: 0.843rem;
  }

  .open {
    width: 0.602rem;
    height: 0.454rem;
  }
}

.navBody_H {
  width: 100%;
  height: 1.39rem;
}

.navBody {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s;

  .navBody_con {
    width: 5.89rem;
    height: 100vh;
    background: #fff;

    .navBody_con_top {
      width: 100%;
      height: 1.39rem;
      border-bottom: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.76rem 0 0.4rem;

      .navBody_con_top_logo {
        height: 0.84rem;
      }

      .navClose {
        width: 0.31rem;
        height: 0.31rem;
      }
    }

    .navBody_list {
      width: 100%;
      height: 0.54rem;
      align-items: center;
      display: flex;
      padding: 0 0 0 0.7rem;
      margin: 0.85rem 0;

      .navBody_list_img {
        width: 0.57rem;
        height: 0.54rem;
      }

      .navBody_list_title {
        font-size: 0.32rem;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #444;
        line-height: 0.32rem;
        margin-left: 0.33rem;
      }
    }
  }
}

.slide-enter {
  transform: translateX(0%);
}

// .slide-enter-to{
//   transform: translateX(-100%);
// }</style>