import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import router from "@/router";
import "@/styles/index.css"; // 引入初始化css
import "@/styles/element-#C20C0C/index.css"; // 引入自定义elementUI主题
import 'remixicon/fonts/remixicon.css' // 引入字体图标
import store from "@/store";

import Header from '@/components/Header'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'; // 注意，这里使用的是swiper-bundle.css，它包含了swiper的所有功能
Vue.component('Header', Header);
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);

// 引入相关API请求接口
import API from "@/api";

// 是否移动端
let isAndroid = "";
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  isAndroid = true // 手机端
} else {
  isAndroid = false // alert('PC端')
}
Vue.prototype.$isAndroid = isAndroid

Vue.config.productionTip = false;
new Vue({
  render: (h) => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this; // 注册全局事件总线
    Vue.prototype.$API = API;
  },
}).$mount("#app");

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

