import instance from "./instance";

export default {

  //歌单分类
  getCatlist: () =>
      instance({
        url: "/playlist/catlist",
        method: "get",
      }),
  // 歌单列表
  playList: ({cat = "全部", limit = 50, pageNo = 1}) => {
    let offset = (pageNo - 1) * limit;
    return instance({
      url: "/top/playlist",
      method: "post",
      params: {
        order: "hot",
        cat,
        limit,
        offset,
      },
    });
  },
  // 获取歌单详细
  playlistDetail: (id) =>
      instance.get(`/playlist/detail?id=${id}&s=8`),
}